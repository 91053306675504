// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaA7N0pg-RFHw5-Q_ebIIY9KleGlv_Rh4",
  authDomain: "speednote-e37c5.firebaseapp.com",
  projectId: "speednote-e37c5",
  storageBucket: "speednote-e37c5.appspot.com",
  messagingSenderId: "515961425704",
  appId: "1:515961425704:web:dbe8a2c28e09afabbb8a1d",
  measurementId: "G-7ZJ2FHTG9M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

export { auth, db };
