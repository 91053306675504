// src/analytics.js
import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel
mixpanel.init('700d871d5a6330b49dc0290a1e8d9347', {
  debug: process.env.NODE_ENV !== 'production',
  track_pageview: true,
  persistence: 'localStorage'
});

// Hotjar initialization
export const initHotjar = () => {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5122533,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

// Helper function for Mixpanel tracking
export const track = (name, props) => {
  mixpanel.track(name, props);
}

// Helper function for Mixpanel user identification
export const identify = (id) => {
  mixpanel.identify(id);
}