import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms of Service</h1>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Acceptance of Terms</h2>
            <p className="text-gray-600">
              By accessing or using SpeedNote, you agree to be bound by these Terms of Service and all applicable laws and regulations.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">2. Use of Service</h2>
            <p className="text-gray-600">
              SpeedNote is provided for your personal, non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information obtained from SpeedNote. Acceptable Use Policy
    You are responsible for all your activity in connection with SpeedNote AI. Make sure that you use SpeedNote AI in a manner that complies with the law. If your use of SpeedNote AI is prohibited by applicable laws, then you aren’t authorized to use SpeedNote AI. We can’t and won’t be responsible for you using SpeedNote AI in a way that breaks the law.
You also agree that you will not use SpeedNote AI in a manner that:

Is fraudulent or threatening;
Jeopardizes the security of your SpeedNote AI account or anyone else’s (such as allowing someone else to log into SpeedNote AI as you, or sharing your account or password with someone);
Attempts, in any manner, to obtain the password, account, or other security information of any other user;
Violates the security of any computer network, or cracks any passwords or security encryption codes;
Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to SpeedNote AI.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">3. Limitation of Liability</h2>
            <p className="text-gray-600">
            To the fullest extent allowed by applicable law, under no circumstances and under no legal theory shall SpeedNote AI, its licensors, or its suppliers be liable to you or to any other person for any indirect, special, incidental, or consequential damages of any kind, or any amount, in the aggregate, in excess of the greater of (1) $100 or (2) the amounts paid and/or payable by you to us in connection with SpeedNote AI in the twelve-month period preceding the applicable claim.            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">4. Privacy</h2>
            <p className="text-gray-600">
              Your use of SpeedNote is also governed by our Privacy Policy, which is incorporated into these Terms of Service by reference.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-3">5. Changes to Terms</h2>
            <p className="text-gray-600">
              We reserve the right to modify these terms at any time. Your continued use of SpeedNote after any such changes constitutes your acceptance of the new Terms of Service.
            </p>
          </section>

          {/* Add more sections as needed */}

        </div>
      </div>
    </div>
  );
};

export default TermsOfService;