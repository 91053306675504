import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { track } from './analytics';

function SuccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    track('Upgrade Success Page Viewed');
    const timer = setTimeout(() => {
      navigate('/notes');
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-purple-100 to-white">
      <div className="text-center">
        <CheckCircleIcon className="mx-auto h-24 w-24 text-green-500" />
        <h2 className="mt-4 text-3xl font-bold text-gray-900">Thank you for subscribing!</h2>
        <p className="mt-2 text-xl text-gray-600">Your account has been upgraded to SpeedNote Pro.</p>
        <p className="mt-4 text-gray-500">Redirecting you to your notes in 5 seconds... (you may be prompted to log-in)</p>
      </div>
    </div>
  );
}

export default SuccessPage;