import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import NotesAreaPremium from './NotesAreaPremium';
import NotesAreaNonPremium from './NotesAreaNonPremium';
import CircularProgress from '@mui/material/CircularProgress';  // Import MUI progress circle

function NotesArea() {
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);  // State to track loading status

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User is authenticated:', user.uid);
        fetchPremiumStatus();
      } else {
        console.log('User is not authenticated');
        setLoading(false);  // Set loading to false if no user is authenticated
      }
    });

    const fetchPremiumStatus = async () => {
      console.log('Fetching premium status...');
      if (auth.currentUser) {
        const userRef = doc(db, 'Users', auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const premiumStatus = userSnap.data().isPremium;
          console.log('User premium status:', premiumStatus);
          setIsPremium(premiumStatus);
        } else {
          console.log('User document does not exist');
        }
      }
      setLoading(false);  // Set loading to false after fetching the status
    };

    return () => unsubscribe();
  }, []);

  console.log('isPremium state:', isPremium);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: 'purple' }} />
      </div>
    );
  }

  return (
    <div>
      {isPremium ? (
        <>
          <NotesAreaPremium />
          {console.log('Rendering NotesAreaPremium component')}
        </>
      ) : (
        <>
          <NotesAreaNonPremium />
          {console.log('Rendering NotesAreaNonPremium component')}
        </>
      )}
      <footer style={{
        position: 'relative',
        bottom: 0,
        width: '100%',
        backgroundColor: '#f8f8f8',
        textAlign: 'center',
        padding: '10px 0',
        fontSize: '14px',
        color: '#555',
        borderTop: '1px solid #e7e7e7'
      }}>
        <a href="/tos" style={{ margin: '0 15px', color: '#555', textDecoration: 'none' }}>Terms of Service</a>
        <a href="/privacy" style={{ margin: '0 15px', color: '#555', textDecoration: 'none' }}>Privacy Policy</a>
        <a href="/contact" style={{ margin: '0 15px', color: '#555', textDecoration: 'none' }}>Contact</a>
      </footer>
    </div>
  );
}


export default NotesArea;
