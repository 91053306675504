import React from 'react';

const Contact = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Contact Us</h1>
          
          <p className="text-gray-600 mb-4">
            We're here to help! If you have any questions, concerns, or feedback about SpeedNote, please don't hesitate to reach out to us.
          </p>

          <p className="text-gray-600 mb-4">
            You can contact our support team at:
          </p>

          <p className="text-xl font-semibold text-purple-600 mb-8">
            support@speednote.ai
          </p>

          <p className="text-gray-600 mb-4">
            Our team typically responds within 3-5 business days.
          </p>

        </div>
      </div>
    </div>
  );
};

export default Contact;